import React from 'react';
import Layout from 'components/layout';
import Seo from 'components/seo';
import ContactUsComponent from 'components/@contactus';

function ContactUsAppPage() {
  return (
    <Layout hideHeader={true} hideDownload={true}>
      <Seo title="Pintarnya Kerja | Kontak Kami" />
      <ContactUsComponent />
    </Layout>
  );
}

export default ContactUsAppPage;
