import React from 'react';
import Link from 'components/link';
import './styles.scss';

function ContactUs() {
  return (
    <div className="contactus">
      <section>
        <h1>Kontak Kami</h1>
        <h4>Butuh Bantuan?</h4>
        <h5>Customer Service Pintarnya (Untuk Pencari Kerja)</h5>
        <p>
          Email:{' '}
          <Link
            href="mailto:kontak@pintarnya.com"
            rel="noopener noreferrer"
            target="_blank"
            className="link_wa"
          >
            <u>kontak@pintarnya.com</u>
          </Link>
        </p>
        <p>
          WhatsApp: +62 812 1022 5029 atau{' '}
          <Link
            href="https://wa.me/6281210225029"
            rel="noopener noreferrer"
            target="_blank"
            className="link_wa"
          >
            wa.me/+6281210225029
          </Link>
        </p>
      </section>

      <section>
        <h5>Customer Service Pintarnya (Untuk Perusahaan)</h5>
        <p>
          Email:{' '}
          <Link
            href="mailto:perusahaan@pintarnya.com"
            rel="noopener noreferrer"
            target="_blank"
            className="link_wa"
          >
            <u>perusahaan@pintarnya.com</u>
          </Link>
        </p>
        <p>
          WhatsApp: +62 812 1022 5029 atau{' '}
          <Link
            href="https://wa.me/6281210225029"
            rel="noopener noreferrer"
            target="_blank"
            className="link_wa"
          >
            wa.me/+6281210225029
          </Link>
        </p>
      </section>

      <section>
        <h5>Alamat Pintarnya</h5>
        <p>PT. Pintarnya Solusi Teknologi - Head Office</p>
        <p>
          Gedung 18 Office Park, Jl. TB Simatupang No.25 Suite A2, Kebagusan,
        </p>
        <p>Pasar Minggu, Jakarta Selatan. 12520.</p>
      </section>

      <section>
        <h5>Jam Operasional: 08.00-17.00</h5>
      </section>

      <section>
        <h5>Media Sosial Kami</h5>
        <ul>
          <li>
            <a
              className="link_wa"
              href="https://www.facebook.com/pintarnya.id/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Facebook
            </a>
          </li>
          <li>
            <a
              className="link_wa"
              href="https://www.instagram.com/pintarnya.id/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Instagram
            </a>
          </li>
          <li>
            <a
              className="link_wa"
              href="https://www.linkedin.com/company/pintarnya"
              rel="noopener noreferrer"
              target="_blank"
            >
              LinkedIn
            </a>
          </li>
          <li>
            <a
              className="link_wa"
              href="https://twitter.com/PintarnyaID"
              rel="noopener noreferrer"
              target="_blank"
            >
              Twitter
            </a>
          </li>
          <li>
            <a
              className="link_wa"
              href="https://www.youtube.com/@pintarnyaid"
              rel="noopener noreferrer"
              target="_blank"
            >
              Youtube
            </a>
          </li>
          <li>
            <a
              className="link_wa"
              href="https://www.tiktok.com/@pintarnya.id"
              rel="noopener noreferrer"
              target="_blank"
            >
              Tiktok
            </a>
          </li>
        </ul>
      </section>
    </div>
  );
}

export default ContactUs;
